<template>
  <div>
    <Loader :loading="isLoader" v-if="isLoader" />
    <div
      class="products flex mr-5 modal-backdrop"
      :class="isTable ? 'flex-row' : 'flex-col'"
      v-else
    >
      <!-- Message on save -->
      <div class="save-backdrop" :class="isContinueSave ? 'open' : ''">
        <div v-if="isContinueSave" class="continue-save">
          <div class="confirmation-text my-20 pt-5 font-bold text-white">
            Changes made will override the current product details
          </div>
          <div class="flex flex-row flex flex-row justify-between">
            <Button
              buttonType="grey"
              class="cancelBtn"
              @click="discardChanges()"
            >
              Cancel
            </Button>
            <Button buttonType="primary" @click="updateProductMapping()">
              OK
            </Button>
          </div>
        </div>
      </div>
      <!-- CUC code -->
      <div
        class="flex flex-row pb-4 items-center"
        v-if="formulationBrandProductMappingPage == 'product'"
      >
        <label
          class="label w-1/4 font-bold text-right pr-4"
          :class="{ hidden: isTable }"
          >CUC Code:<span class="text-red-600">*</span></label
        >
        <div
          class="select-box pl-2 relative"
          :class="isTable ? 'w-full' : 'w-3/4'"
        >
          <AutoCompleteDropdown
            :options="cucCodes"
            v-model="selectedCucCode"
            @selected-item="setSelectedCucCode"
            v-if="isCucCodeRefresh"
            :apiPath="apiPath"
            placeholder="Enter 3 digits of CUC Code"
            isAutoFetch
            autoFetchType="CucCodes"
          /><Loader :loading="isCucLoader" :size="size" />
        </div>
      </div>
      <!-- Revision -->
      <div
        class="flex flex-row pb-4 items-center"
        v-if="formulationBrandProductMappingPage == 'product'"
      >
        <label
          class="label w-1/4 font-bold text-right pr-4"
          :class="{ hidden: isTable }"
          >Revision:<span class="text-red-600">*</span></label
        >
        <div
          class="select-box pl-2 relative"
          :class="isTable ? 'w-full' : 'w-3/4'"
        >
          <AutoCompleteDropdown
            :options="revisions"
            v-model="selectedRevision"
            @selected-item="setSelectedRevision"
            v-if="isRevisionsRefresh"
            :limit="limit"
          /><Loader :loading="isRevisionLoader" :size="size" />
        </div>
      </div>
      <!-- Description -->
      <div
        class="flex flex-row pb-4 items-center"
        v-if="formulationBrandProductMappingPage == 'product'"
      >
        <label
          class="label w-1/4 font-bold text-right pr-4"
          :class="{ hidden: isTable }"
          >Description:</label
        >
        <div
          class="tooltip select-box pl-2 relative"
          :class="isTable ? 'w-full' : 'w-3/4'"
        >
          <input type="text" disabled v-model="cucDescription" /><Tooltip
            :hoverText="cucDescription"
          />
        </div>
      </div>
      <!--Country-->
      <div class="flex flex-row pb-4 items-center">
        <label
          class="label w-1/4 font-bold text-right pr-4"
          :class="{ hidden: isTable }"
          >Country:<span class="text-red-600">*</span></label
        >
        <div
          class="select-box pl-2 relative"
          :class="isTable ? 'w-full' : 'w-3/4'"
        >
          <AutoCompleteDropdown
            :options="countries"
            v-model="selectedCountry"
            @selected-item="setSelectedCountry"
            v-if="isProductType == 'add'"
          />
          <input
            type="text"
            v-if="isProductType != 'add'"
            v-model="selectedCountry"
            :title="selectedCountry"
            disabled
          />
        </div>
      </div>
      <!--Language-->
      <div class="flex flex-row pb-4 items-center">
        <label
          class="label w-1/4 font-bold text-right pr-4"
          :class="{ hidden: isTable }"
          >Language:<span class="text-red-600">*</span></label
        >
        <div
          class="select-box pl-2 relative"
          :class="isTable ? 'w-full' : 'w-3/4'"
        >
          <AutoCompleteDropdown
            :options="languages"
            v-model="selectedLanguage"
            @selected-item="setSelectedLanguage"
            v-if="isProductType == 'add' && isLanguageRefresh"
          /><Loader :loading="isLangLoader" :size="dataLoaderSize" />
          <input
            type="text"
            v-if="isProductType != 'add'"
            v-model="selectedLanguage"
            :title="selectedLanguage"
            disabled
          />
        </div>
      </div>
      <!--Brand-->
      <div class="flex flex-row pb-4 items-center">
        <label
          class="label w-1/4 font-bold text-right pr-4"
          :class="{ hidden: isTable }"
          >Brand:<span class="text-red-600">*</span></label
        >
        <div
          class="select-box pl-2 relative"
          :class="isTable ? 'w-full' : 'w-3/4'"
        >
          <AutoCompleteDropdown
            :options="brands"
            v-model="selectedBrand"
            @selected-item="setSelectedBrand"
            v-if="isProductType == 'add' && isBrandRefresh"
          />
          <Loader :loading="isBrandLoader" :size="dataLoaderSize" />
          <input
            type="text"
            v-if="isProductType != 'add'"
            v-model="selectedBrand"
            :title="selectedBrand"
            disabled
          />
        </div>
      </div>
      <!--Product-->
      <div class="flex flex-row pb-4 items-center">
        <label
          class="label w-1/4 font-bold text-right pr-4"
          :class="{ hidden: isTable }"
          >Product Name:<span class="text-red-600">*</span></label
        >
        <div
          class="select-box pl-2 relative"
          :class="isTable ? 'w-full' : 'w-3/4'"
        >
          <AutoCompleteDropdown
            :options="products"
            v-model="selectedProduct"
            @selected-item="setSelectedProduct"
            v-if="isProductType == 'add' && isProductsRefresh"
          /><Loader :loading="isProductLoader" :size="dataLoaderSize" />
          <input
            type="text"
            v-if="isProductType != 'add'"
            v-model="selectedProduct"
            :title="selectedProduct"
            disabled
          />
        </div>
      </div>
      <!--Variation-->
      <div class="flex flex-row pb-4 items-center">
        <label
          class="label w-1/4 font-bold text-right pr-4"
          :class="{ hidden: isTable }"
          >Variation Name:<span class="text-red-600">*</span></label
        >
        <div
          class="select-box pl-2 relative"
          :class="isTable ? 'w-full' : 'w-3/4'"
        >
          <AutoCompleteDropdown
            :options="variations"
            v-model="selectedVariation"
            @selected-item="setSelectedVariation"
            v-if="isProductType == 'add' && isVariationsRefresh"
          /><Loader :loading="isVariationLoader" :size="dataLoaderSize" />
          <input
            type="text"
            v-if="isProductType != 'add'"
            v-model="selectedVariation"
            :title="selectedVariation"
            disabled
          />
        </div>
      </div>
      <!--Variation date range-->
      <div class="product-date-range flex flex-row pb-4 items-center ml-2">
        <!--Variation start date-->
        <div class="product-startdate flex flex-row items-center">
          <label
            class="label font-bold text-right pr-6"
            :class="{ hidden: isTable }"
            >Variation Start Date:</label
          >
          <div class="date-container">
            <DatePicker
              v-model="variationStartdate"
              format="YYYY-MM-DD"
              valueType="format"
              disabled
            />
          </div>
        </div>
        <!--Variation end date-->
        <div class="product-enddate flex flex-row items-center">
          <label
            class="label font-bold text-right pr-2"
            :class="{ hidden: isTable }"
            >Variation End Date:</label
          >
          <div class="date-container pl-4">
            <DatePicker
              v-model="variationEnddate"
              format="YYYY-MM-DD"
              valueType="format"
              disabled
            />
          </div>
        </div>
      </div>
      <!--Formulation date range-->
      <div class="flex flex-row pb-4 items-center ml-12">
        <!--Formulation start date-->
        <div class="formulation-startdate flex flex-row items-center">
          <label
            class="label font-bold text-right pr-4"
            :class="{ hidden: isTable }"
            >CUC Start Date:<span class="text-red-600">*</span></label
          >
          <div class="date-container">
            <DatePicker
              v-model="formulationStartDate"
              format="YYYY-MM-DD"
              valueType="format"
              :disabled="
                selectedCucStatus === 'Delisted' ||
                selectedCucStatus === 'Inactive'
              "
            />
          </div>
        </div>
        <!--Formulation end date-->
        <div class="formulation-enddate flex flex-row ml-16 items-center">
          <label
            class="label font-bold text-right pr-4"
            :class="{ hidden: isTable }"
            >CUC End Date:</label
          >
          <div class="date-container">
            <DatePicker
              v-model="formulationEnddate"
              format="YYYY-MM-DD"
              disabled
              valueType="format"
            />
          </div>
        </div>
      </div>
      <!-- Historic -->
      <div
        class="addproducts__item flex flex-row pb-4"
        v-if="selectedCucStatus === 'Delisted'"
      >
        <label
          class="label w-1/4 font-bold text-right pr-4"
          :class="{ hidden: isTable }"
          >Historic:</label
        >
        <div class="filters__item ml-2 tooltip" @mousedown="onCheckboxChange()">
          <Checkbox :checked="isHistoric" /><Tooltip
            :position="'right'"
            v-if="isHistoric"
            :hoverText="'Formulation will flow to WIOP'"
          />
          <Tooltip
            :position="'right'"
            v-else
            :hoverText="'Formulation will not flow to WIOP'"
          />
        </div>
      </div>
      <!-- Comments -->
      <div
        class="flex flex-row pb-4 items-center"
        v-if="selectedCucStatus === 'Delisted' && !isHistoric"
      >
        <label
          class="label w-1/4 font-bold text-right pr-4"
          :class="{ hidden: isTable }"
          >Comments: <span class="text-red-600">*</span></label
        >
        <div
          class="select-box pl-2 relative"
          :class="isTable ? 'w-full' : 'w-3/4'"
        >
          <textarea v-model="comments"></textarea>
        </div>
      </div>
    </div>
    <!--Buttons-->
    <div class="popup-action__button">
      <Button
        type="primary"
        @click="save()"
        :disabled="isSave"
        v-if="isModalLoaded"
        ><svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20">
          <path
            d="M0 2C0 .9.9 0 2 0h14l4 4v14a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2V2zm5 0v6h10V2H5zm6 1h3v4h-3V3z"
          />
        </svg>
        Save</Button
      >
    </div>
  </div>
</template>
<script>
import DatePicker from "vue2-datepicker";
import { mapGetters, mapMutations, mapActions } from "vuex";
import loggerService from "../../services/logger";
import authentication from "../../authentication";
import formulationMappingService from "../../services/formulationMappings";
import productService from "../../services/products";
export default {
  name: "product",
  components: {
    Loader: () => import(/* webpackPrefetch: true */ "./../common/Loader"),
    AutoCompleteDropdown: () =>
      import(/* webpackPrefetch: true */ "./../common/AutoCompleteDropdown"),
    Button: () => import(/* webpackPrefetch: true */ "./../common/Button"),
    Checkbox: () => import(/* webpackPrefetch: true */ "../common/Checkbox"),
    Tooltip: () => import(/* webpackPrefetch: true */ "../common/Tooltip"),
    DatePicker,
  },
  props: {
    isTable: {
      type: Boolean,
      default: false,
    },
  },
  data: () => ({
    limit: 1,
    isCucLoader: false,
    isRevisionLoader: false,
    isCucCodeRefresh: true,
    isRevisionsRefresh: true,
    selectedCucCode: "",
    selectedRevision: "",
    cucCodes: [],
    revisions: [],
    productStartdate: "",
    productEnddate: "",
    variationStartdate: "",
    variationEnddate: "",
    formulationStartDate: "",
    formulationEnddate: "",
    isLoader: true,
    size: "15px",
    isLangLoader: false,
    isBrandLoader: false,
    isProductLoader: false,
    isVariationLoader: false,
    dataLoaderSize: "5px",
    isLanguageRefresh: true,
    isBrandRefresh: true,
    isProductsRefresh: true,
    isVariationsRefresh: true,
    displayMode: "horizantal",
    languages: [],
    brands: [],
    products: [],
    variations: [],
    selectedCountry: "",
    countryId: "",
    selectedLanguage: "",
    languageId: "",
    selectedBrand: "",
    brandId: "",
    selectedProduct: "",
    selectedVariation: "",
    productId: "",
    variationId: "",
    selectedCucStatus: "",
    cucStatusId: "",
    isHistoric: true,
    isContinueSave: false,
    transId: "",
    temp: "",
    isModalLoaded: false,
    productInformation: null,
    comments: "",
    cucDescription: "",
  }),
  created() {
    setTimeout(() => {
      this.isLoader = false;
      this.isModalLoaded = true;
    }, 800);
    if (this.isProductType === "edit") {
      this.editFormulation(this.formulation);
    }
  },
  computed: {
    apiPath() {
      return `FormulationMapping/GetCUCCodesForFilter/${authentication.getUserEmail()}/`;
    },
    userEmail() {
      if (!authentication.isAuthenticated) {
        return null;
      }
      return authentication.getUserEmail();
    },
    ...mapGetters({
      isProductType: "GET_IS_PRODUCT_TYPE",
      formulation: "GET_FORMULATION",
      countries: "GET_COUNTRIES",
      isProductListed: "GET_IS_PRODUCT_LISTED",
      cucStatus: "GET_CUC_STATUS",
      primaryCucCode: "GET_CUC_CODE_PRIMARY",
      primaryRevision: "GET_REVISION_PRIMARY",
      formulationBrandProductMappingPage: "GET_FORMULATION_IN_PRODUCT",
    }),
    isSave() {
      if (this.formulationBrandProductMappingPage == "product") {
        return this.selectedCucCode != "" &&
          this.selectedRevision != "" &&
          this.selectedCountry != "" &&
          this.selectedLanguage != "" &&
          this.selectedBrand != "" &&
          this.selectedProduct != "" &&
          this.selectedVariation != "" &&
          this.variationStartdate != "" &&
          this.variationEnddate != "" &&
          this.formulationStartDate != ""
          ? false
          : true;
      } else {
        return this.selectedCountry != "" &&
          this.selectedLanguage != "" &&
          this.selectedBrand != "" &&
          this.selectedProduct != "" &&
          this.selectedVariation != "" &&
          this.variationStartdate != "" &&
          this.variationEnddate != "" &&
          this.formulationStartDate != "" &&
          this.formulationStartDate != null
          ? false
          : true;
      }
    },
  },
  methods: {
    ...mapActions({
      getFormulations: "fetchFormulations",
    }),
    ...mapMutations({ updateFormulations: "SET_FORMULATIONS" }),
    clearSelected() {
      this.selectedLanguage = "";
      this.selectedBrand = "";
    },
    setSelectedCucCode(cucCode) {
      if (cucCode === true) {
        this.revisions = [];
        this.isRevisionRefresh();
        return;
      }
      this.selectedCucCode = cucCode.Value;
      this.getRevisions(cucCode.Value);
    },
    async getRevisions(code) {
      try {
        const { data } = await formulationMappingService.getCUCRevisions(code);
        this.revisions = data;
        this.isRevisionLoader = false;
        this.isRevisionRefresh();
      } catch (error) {
        loggerService.logError(error);
        this.revisions = [];
        this.isRevisionLoader = false;
        this.isRevisionRefresh();
      }
    },
    isRevisionRefresh() {
      this.isRevisionsRefresh = false;
      this.$nextTick(() => {
        this.isRevisionsRefresh = true;
      });
    },
    setSelectedRevision(revision) {
      if (revision === true) {
        this.selectedRevision = "";
        this.cucDescription = "";
        return;
      }
      this.selectedRevision = revision.Value;
      this.cucDescription = revision.Info;
    },
    setSelectedCountry(country) {
      if (country === true) {
        this.selectedCountry = "";
        this.clearSelected();
        this.languages = [];
        this.brands = [];
        this.products = [];
        this.selectedVariation = "";
        this.variations = [];
        this.variationStartdate = "";
        this.variationEnddate = "";
        this.isVariationRefresh();
        this.isLanguagesRefresh();
        this.isBrandsRefresh();
        this.isProductRefresh();
        return;
      }
      this.isLangLoader = true;
      this.isBrandLoader = true;
      setTimeout(() => {
        this.isLangLoader = false;
        this.isBrandLoader = false;
      }, 500);
      this.clearSelected();
      this.selectedCountry = country.Value;
      this.countryId = country.Id;
      this.getLanguages(country.Id);
      this.getBrands(country.Id);
      this.isLanguagesRefresh();
      this.isBrandsRefresh();
    },
    setSelectedLanguage(language) {
      if (language === true) {
        this.selectedLanguage = "";
        return;
      } else {
        this.selectedLanguage = language.Value;
        this.languageId = language.Id;
      }
      this.isVariationRefresh();
    },
    setSelectedBrand(brand) {
      if (brand === true) {
        this.selectedBrand = "";
        this.selectedProduct = "";
        this.products = [];
        this.variations = [];
        this.variationStartdate = "";
        this.variationEnddate = "";
        this.isProductRefresh();
        this.isVariationRefresh();
        return;
      }
      this.isProductLoader = true;
      setTimeout(() => {
        this.isProductLoader = false;
      }, 500);
      this.selectedBrand = brand.Value;
      this.brandId = brand.Id;
      this.getProducts();
    },
    setSelectedProduct(product) {
      if (product === true) {
        this.selectedProduct = "";
        this.selectedVariation = "";
        this.variations = [];
        this.variationStartdate = "";
        this.variationEnddate = "";
        this.isVariationRefresh();
        return;
      }
      this.isVariationLoader = true;
      setTimeout(() => {
        this.isVariationLoader = false;
      }, 500);
      this.selectedProduct = product.Value;
      this.productId = product.Id;
      this.variations = [];
      this.variationStartdate = "";
      this.variationEnddate = "";
      this.getVariations();
    },
    async getLanguages(country) {
      const { data } = await formulationMappingService.getLanguagesByCountry(
        country
      );

      this.languages = data;
      this.isLangLoader = false;
      this.isLanguagesRefresh();
    },
    async getBrands(country) {
      const { data } = await formulationMappingService.getBrandsByCountry(
        country
      );

      this.brands = data;
      this.isBrandLoader = false;
      this.isBrandsRefresh();
    },
    async getProducts() {
      let payLoad = {
        countries: this.countryId,
        brands: this.selectedBrand,
        selectDelisted: false,
      };
      try {
        const { data } = await productService.getProducts(payLoad);

        this.products = data;
        this.isProductLoader = false;
        this.isProductRefresh();
      } catch (error) {
        loggerService.logError(error);
        this.products = [];
        this.isProductLoader = false;
        this.isProductRefresh();
      }
    },
    async getVariations() {
      let self = this;
      try {
        const payLoad = {
          productId: this.productId,
          languageId: this.languageId,
          countryId: this.countryId,
        };
        const { data } = await formulationMappingService.getVariationsProduct(
          payLoad
        );

        self.productInformation = data;
        self.variations = [];
        self.productInformation.forEach(function (item) {
          self.variations.push({
            Value: item.Variation_Name,
            Id: item.Variation_Id,
            Info: "",
          });
        });
        self.isVariationRefresh();
      } catch (error) {
        loggerService.logError(error);
        self.productInformation = [];
      }
    },
    getVariationDetails(variation) {
      let obj = this.productInformation.find(
        (x) => x.Variation_Id === variation
      );
      this.variationStartdate = obj.VariationStartDate;
      this.variationEnddate = obj.VariationEndDate;
    },
    setSelectedVariation(variation) {
      if (variation === true) {
        this.selectedVariation = "";
        this.variationStartdate = "";
        this.variationEnddate = "";
      } else {
        this.selectedVariation = variation.Value;
        this.variationId = variation.Id;
        this.getVariationDetails(this.variationId);
      }
    },
    save() {
      if (this.validateDate()) {
        // save on add
        if (this.isProductType === "add") {
          this.addProductMapping();
        }
        // save on edit
        else {
          if (this.selectedCucStatus === "Delisted" && !this.isHistoric) {
            if (this.comments === "") {
              loggerService.logError(
                "Please provide comments for unselecting historic"
              );
            } else {
              this.isContinueSave = true;
            }
          } else {
            this.isContinueSave = true;
          }
        }
      }
    },
    async addProductMapping() {
      try {
        let addMapping = {
          CountryID: parseInt(this.countryId),
          LanguageID: parseInt(this.languageId),
          BrandID: parseInt(this.brandId),
          ProductID: parseInt(this.productId),
          VariationID: parseInt(this.variationId),
          CucStartDate: this.formulationStartDate,
          CreatedBy: authentication.getUserEmail(),
        };

        if (this.formulationBrandProductMappingPage == "product") {
          addMapping.PlmMaterialId = this.selectedCucCode;
          addMapping.Revision = this.selectedRevision;
        } else {
          addMapping.PlmMaterialId = this.primaryCucCode;
          addMapping.Revision = this.primaryRevision.Value;
        }

        const { data } = await formulationMappingService.addFormulationMapping(
          addMapping
        );

        if (
          data.Content[0].IsDuplicate === false &&
          data.Content[0].IsCucStartDateSmaller === false &&
          data.Content[0].IsArchived === false
        ) {
          this.$emit("on-close");
          if (this.formulationBrandProductMappingPage != "product") {
            this.getProductMappings();
          }
          loggerService.logSuccess(data.Message);
        } else if (
          data.Content[0].IsDuplicate ||
          data.Content[0].IsCucStartDateSmaller ||
          data.Content[0].IsArchived
        ) {
          loggerService.logError600(data.Message);
        }
      } catch (error) {
        loggerService.logError(error);
      }
    },
    async updateProductMapping() {
      try {
        let updateMapping = {
          TransId: this.transId,
          VariationID: this.variationId,
          PlmMaterialId: this.primaryCucCode,
          Revision: this.primaryRevision.Value,
          CucStartDate: this.formulationStartDate,
          UpdatedBy: authentication.getUserEmail(),
          Historic: this.isHistoric,
          Comments: this.comments,
        };
        const {
          data,
        } = await formulationMappingService.updateFormulationMapping(
          updateMapping
        );

        if (
          data.Content[0].IsDuplicate === false &&
          data.Content[0].IsCucStartDateSmaller === false &&
          data.Content[0].IsArchived === false
        ) {
          this.getProductMappings();
          this.$emit("on-close");
          loggerService.logSuccess(data.Message);
        } else if (
          data.Content[0].IsDuplicate ||
          data.Content[0].IsCucStartDateSmaller ||
          data.Content[0].IsArchived
        ) {
          loggerService.logError600(data.Message);
          return;
        }
        this.$emit("on-close");
      } catch (error) {
        loggerService.logError(error);
      }
    },
    validateDate() {
      const formulationDate = this.formulationStartDate;
      const variationEndDate = this.variationEnddate;
      const variationStartDate = this.variationStartdate;
      if (
        !this.comparedates(
          formulationDate,
          variationStartDate,
          variationEndDate
        )
      ) {
        if (variationEndDate == "" || variationEndDate == null) {
          loggerService.logError600(
            "CUC start date should be greater than variation start date"
          );
        } else {
          loggerService.logError600(
            "CUC start date should be between Variation start date and Variation end date"
          );
        }
        return false;
      } else {
        return true;
      }
    },
    convertDate(str) {
      let parts;
      if (str === null || str === undefined) return;
      if (str.split("-").length > 1) parts = str.split("-");
      else if (str.split("/").length > 1) parts = str.split("/");
      return new Date(parts[0] + "-" + parts[1] + "-" + parts[2]);
    },
    comparedates(formulationDate, variationStartDate, variationEndDate) {
      if (
        formulationDate != "" &&
        variationStartDate != "" &&
        variationEndDate != "" &&
        variationEndDate != null
      ) {
        if (
          this.convertDate(variationStartDate) <=
            this.convertDate(formulationDate) &&
          this.convertDate(formulationDate) <=
            this.convertDate(variationEndDate)
        ) {
          return true;
        } else {
          return false;
        }
      } else if (
        (formulationDate != "" &&
          variationStartDate != "" &&
          variationEndDate == "") ||
        variationEndDate == null
      ) {
        if (
          this.convertDate(variationStartDate) >
          this.convertDate(formulationDate)
        ) {
          return false;
        } else {
          return true;
        }
      }
    },
    editFormulation(data) {
      this.selectedCountry = data.Country;
      this.selectedLanguage = data.Language;
      this.selectedBrand = data.Brand;
      this.selectedVariation = data.ProductVariationName;
      this.variationStartdate = data.ProductVariationStartDate;
      this.variationEnddate = data.ProductVariationEndDate;
      this.formulationStartDate = data.CucStartDate;
      this.formulationEnddate = data.CucEndDate;
      this.transId = data.TransId;
      this.variationId = data.ProductVariationId;
      this.selectedCucStatus = data.CucStatusText;
      this.isHistoric = data.Historic;
      this.selectedProduct = data.Product;
      this.Comments = data.Comments;
    },
    onCheckboxChange() {
      if (this.selectedCucStatus != "Current") {
        this.isHistoric = this.isHistoric ? false : true;
      }
    },
    discardChanges() {
      this.isContinueSave = false;
      loggerService.logInfo600("The changes you have made will be discarded");
    },
    isLanguagesRefresh() {
      this.isLanguageRefresh = false;
      this.$nextTick(() => {
        this.isLanguageRefresh = true;
      });
    },
    isBrandsRefresh() {
      this.isBrandRefresh = false;
      this.$nextTick(() => {
        this.isBrandRefresh = true;
      });
    },
    isProductRefresh() {
      this.isProductsRefresh = false;
      this.$nextTick(() => {
        this.isProductsRefresh = true;
      });
    },
    isVariationRefresh() {
      this.isVariationsRefresh = false;
      this.$nextTick(() => {
        this.isVariationsRefresh = true;
      });
    },
    async getProductMappings() {
      try {
        const payLoad = {
          cucCode: this.primaryCucCode,
          revisionNumber: this.primaryRevision.Value,
          cucStatus: this.cucStatus === null ? "" : this.cucStatus,
        };
        this.getFormulations(payLoad);
        this.isLoader = false;
      } catch (error) {
        loggerService.logError(error);
        this.updateFormulations([]);
        this.isLoader = false;
      }
    },
  },
};
</script>


<style lang="scss" scoped>
.products {
  .select-box {
    .v-spinner {
      position: absolute;
      top: -4px;
    }
  }
  .save-backdrop.open {
    background-color: rgba(0, 0, 0, 0.4);
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    z-index: 9;
    .continue-save {
      background: #ed6663;
      box-shadow: 0 10px 15px -3px rgba(0, 0, 0, 0.1),
        0 4px 6px -2px rgba(0, 0, 0, 0.05);
      border-radius: 0.3rem;
      transition: 0.5s;
      position: relative;
      padding: 10px;
    }
  }
  textarea {
    width: 100%;
    color: #444;
    border: 1px solid #d6d2de;
    resize: none;
    padding: 5px;
    &:focus {
      border: 1px solid #d6d2de !important;
    }
  }
}
.product-enddate {
  margin-left: 1.3rem;
}
.date-container {
  .mx-datepicker {
    width: 120px;
  }
  .mx-input {
    border-radius: 2px !important;
    border: 1px solid #d2d6de !important;
    height: 30px !important;
  }
}
input {
  border: 1px solid #d2d6de !important;
  border-radius: 3px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  padding: 0 5px;
  background: #fff;
  height: 30px;
  opacity: 0.8;
  width: 100%;
}
::-ms-backdrop,
.product-enddate {
  margin-left: 31px;
}
::-ms-backdrop,
.product-date-range {
  margin-left: 10px;
}
</style>
