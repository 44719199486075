var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _vm.isLoader
        ? _c("Loader", { attrs: { loading: _vm.isLoader } })
        : _c(
            "div",
            {
              staticClass: "products flex mr-5 modal-backdrop",
              class: _vm.isTable ? "flex-row" : "flex-col"
            },
            [
              _c(
                "div",
                {
                  staticClass: "save-backdrop",
                  class: _vm.isContinueSave ? "open" : ""
                },
                [
                  _vm.isContinueSave
                    ? _c("div", { staticClass: "continue-save" }, [
                        _c(
                          "div",
                          {
                            staticClass:
                              "confirmation-text my-20 pt-5 font-bold text-white"
                          },
                          [
                            _vm._v(
                              " Changes made will override the current product details "
                            )
                          ]
                        ),
                        _c(
                          "div",
                          {
                            staticClass:
                              "flex flex-row flex flex-row justify-between"
                          },
                          [
                            _c(
                              "Button",
                              {
                                staticClass: "cancelBtn",
                                attrs: { buttonType: "grey" },
                                on: {
                                  click: function($event) {
                                    return _vm.discardChanges()
                                  }
                                }
                              },
                              [_vm._v(" Cancel ")]
                            ),
                            _c(
                              "Button",
                              {
                                attrs: { buttonType: "primary" },
                                on: {
                                  click: function($event) {
                                    return _vm.updateProductMapping()
                                  }
                                }
                              },
                              [_vm._v(" OK ")]
                            )
                          ],
                          1
                        )
                      ])
                    : _vm._e()
                ]
              ),
              _vm.formulationBrandProductMappingPage == "product"
                ? _c(
                    "div",
                    { staticClass: "flex flex-row pb-4 items-center" },
                    [
                      _c(
                        "label",
                        {
                          staticClass: "label w-1/4 font-bold text-right pr-4",
                          class: { hidden: _vm.isTable }
                        },
                        [
                          _vm._v("CUC Code:"),
                          _c("span", { staticClass: "text-red-600" }, [
                            _vm._v("*")
                          ])
                        ]
                      ),
                      _c(
                        "div",
                        {
                          staticClass: "select-box pl-2 relative",
                          class: _vm.isTable ? "w-full" : "w-3/4"
                        },
                        [
                          _vm.isCucCodeRefresh
                            ? _c("AutoCompleteDropdown", {
                                attrs: {
                                  options: _vm.cucCodes,
                                  apiPath: _vm.apiPath,
                                  placeholder: "Enter 3 digits of CUC Code",
                                  isAutoFetch: "",
                                  autoFetchType: "CucCodes"
                                },
                                on: { "selected-item": _vm.setSelectedCucCode },
                                model: {
                                  value: _vm.selectedCucCode,
                                  callback: function($$v) {
                                    _vm.selectedCucCode = $$v
                                  },
                                  expression: "selectedCucCode"
                                }
                              })
                            : _vm._e(),
                          _c("Loader", {
                            attrs: { loading: _vm.isCucLoader, size: _vm.size }
                          })
                        ],
                        1
                      )
                    ]
                  )
                : _vm._e(),
              _vm.formulationBrandProductMappingPage == "product"
                ? _c(
                    "div",
                    { staticClass: "flex flex-row pb-4 items-center" },
                    [
                      _c(
                        "label",
                        {
                          staticClass: "label w-1/4 font-bold text-right pr-4",
                          class: { hidden: _vm.isTable }
                        },
                        [
                          _vm._v("Revision:"),
                          _c("span", { staticClass: "text-red-600" }, [
                            _vm._v("*")
                          ])
                        ]
                      ),
                      _c(
                        "div",
                        {
                          staticClass: "select-box pl-2 relative",
                          class: _vm.isTable ? "w-full" : "w-3/4"
                        },
                        [
                          _vm.isRevisionsRefresh
                            ? _c("AutoCompleteDropdown", {
                                attrs: {
                                  options: _vm.revisions,
                                  limit: _vm.limit
                                },
                                on: {
                                  "selected-item": _vm.setSelectedRevision
                                },
                                model: {
                                  value: _vm.selectedRevision,
                                  callback: function($$v) {
                                    _vm.selectedRevision = $$v
                                  },
                                  expression: "selectedRevision"
                                }
                              })
                            : _vm._e(),
                          _c("Loader", {
                            attrs: {
                              loading: _vm.isRevisionLoader,
                              size: _vm.size
                            }
                          })
                        ],
                        1
                      )
                    ]
                  )
                : _vm._e(),
              _vm.formulationBrandProductMappingPage == "product"
                ? _c(
                    "div",
                    { staticClass: "flex flex-row pb-4 items-center" },
                    [
                      _c(
                        "label",
                        {
                          staticClass: "label w-1/4 font-bold text-right pr-4",
                          class: { hidden: _vm.isTable }
                        },
                        [_vm._v("Description:")]
                      ),
                      _c(
                        "div",
                        {
                          staticClass: "tooltip select-box pl-2 relative",
                          class: _vm.isTable ? "w-full" : "w-3/4"
                        },
                        [
                          _c("input", {
                            directives: [
                              {
                                name: "model",
                                rawName: "v-model",
                                value: _vm.cucDescription,
                                expression: "cucDescription"
                              }
                            ],
                            attrs: { type: "text", disabled: "" },
                            domProps: { value: _vm.cucDescription },
                            on: {
                              input: function($event) {
                                if ($event.target.composing) {
                                  return
                                }
                                _vm.cucDescription = $event.target.value
                              }
                            }
                          }),
                          _c("Tooltip", {
                            attrs: { hoverText: _vm.cucDescription }
                          })
                        ],
                        1
                      )
                    ]
                  )
                : _vm._e(),
              _c("div", { staticClass: "flex flex-row pb-4 items-center" }, [
                _c(
                  "label",
                  {
                    staticClass: "label w-1/4 font-bold text-right pr-4",
                    class: { hidden: _vm.isTable }
                  },
                  [
                    _vm._v("Country:"),
                    _c("span", { staticClass: "text-red-600" }, [_vm._v("*")])
                  ]
                ),
                _c(
                  "div",
                  {
                    staticClass: "select-box pl-2 relative",
                    class: _vm.isTable ? "w-full" : "w-3/4"
                  },
                  [
                    _vm.isProductType == "add"
                      ? _c("AutoCompleteDropdown", {
                          attrs: { options: _vm.countries },
                          on: { "selected-item": _vm.setSelectedCountry },
                          model: {
                            value: _vm.selectedCountry,
                            callback: function($$v) {
                              _vm.selectedCountry = $$v
                            },
                            expression: "selectedCountry"
                          }
                        })
                      : _vm._e(),
                    _vm.isProductType != "add"
                      ? _c("input", {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model",
                              value: _vm.selectedCountry,
                              expression: "selectedCountry"
                            }
                          ],
                          attrs: {
                            type: "text",
                            title: _vm.selectedCountry,
                            disabled: ""
                          },
                          domProps: { value: _vm.selectedCountry },
                          on: {
                            input: function($event) {
                              if ($event.target.composing) {
                                return
                              }
                              _vm.selectedCountry = $event.target.value
                            }
                          }
                        })
                      : _vm._e()
                  ],
                  1
                )
              ]),
              _c("div", { staticClass: "flex flex-row pb-4 items-center" }, [
                _c(
                  "label",
                  {
                    staticClass: "label w-1/4 font-bold text-right pr-4",
                    class: { hidden: _vm.isTable }
                  },
                  [
                    _vm._v("Language:"),
                    _c("span", { staticClass: "text-red-600" }, [_vm._v("*")])
                  ]
                ),
                _c(
                  "div",
                  {
                    staticClass: "select-box pl-2 relative",
                    class: _vm.isTable ? "w-full" : "w-3/4"
                  },
                  [
                    _vm.isProductType == "add" && _vm.isLanguageRefresh
                      ? _c("AutoCompleteDropdown", {
                          attrs: { options: _vm.languages },
                          on: { "selected-item": _vm.setSelectedLanguage },
                          model: {
                            value: _vm.selectedLanguage,
                            callback: function($$v) {
                              _vm.selectedLanguage = $$v
                            },
                            expression: "selectedLanguage"
                          }
                        })
                      : _vm._e(),
                    _c("Loader", {
                      attrs: {
                        loading: _vm.isLangLoader,
                        size: _vm.dataLoaderSize
                      }
                    }),
                    _vm.isProductType != "add"
                      ? _c("input", {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model",
                              value: _vm.selectedLanguage,
                              expression: "selectedLanguage"
                            }
                          ],
                          attrs: {
                            type: "text",
                            title: _vm.selectedLanguage,
                            disabled: ""
                          },
                          domProps: { value: _vm.selectedLanguage },
                          on: {
                            input: function($event) {
                              if ($event.target.composing) {
                                return
                              }
                              _vm.selectedLanguage = $event.target.value
                            }
                          }
                        })
                      : _vm._e()
                  ],
                  1
                )
              ]),
              _c("div", { staticClass: "flex flex-row pb-4 items-center" }, [
                _c(
                  "label",
                  {
                    staticClass: "label w-1/4 font-bold text-right pr-4",
                    class: { hidden: _vm.isTable }
                  },
                  [
                    _vm._v("Brand:"),
                    _c("span", { staticClass: "text-red-600" }, [_vm._v("*")])
                  ]
                ),
                _c(
                  "div",
                  {
                    staticClass: "select-box pl-2 relative",
                    class: _vm.isTable ? "w-full" : "w-3/4"
                  },
                  [
                    _vm.isProductType == "add" && _vm.isBrandRefresh
                      ? _c("AutoCompleteDropdown", {
                          attrs: { options: _vm.brands },
                          on: { "selected-item": _vm.setSelectedBrand },
                          model: {
                            value: _vm.selectedBrand,
                            callback: function($$v) {
                              _vm.selectedBrand = $$v
                            },
                            expression: "selectedBrand"
                          }
                        })
                      : _vm._e(),
                    _c("Loader", {
                      attrs: {
                        loading: _vm.isBrandLoader,
                        size: _vm.dataLoaderSize
                      }
                    }),
                    _vm.isProductType != "add"
                      ? _c("input", {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model",
                              value: _vm.selectedBrand,
                              expression: "selectedBrand"
                            }
                          ],
                          attrs: {
                            type: "text",
                            title: _vm.selectedBrand,
                            disabled: ""
                          },
                          domProps: { value: _vm.selectedBrand },
                          on: {
                            input: function($event) {
                              if ($event.target.composing) {
                                return
                              }
                              _vm.selectedBrand = $event.target.value
                            }
                          }
                        })
                      : _vm._e()
                  ],
                  1
                )
              ]),
              _c("div", { staticClass: "flex flex-row pb-4 items-center" }, [
                _c(
                  "label",
                  {
                    staticClass: "label w-1/4 font-bold text-right pr-4",
                    class: { hidden: _vm.isTable }
                  },
                  [
                    _vm._v("Product Name:"),
                    _c("span", { staticClass: "text-red-600" }, [_vm._v("*")])
                  ]
                ),
                _c(
                  "div",
                  {
                    staticClass: "select-box pl-2 relative",
                    class: _vm.isTable ? "w-full" : "w-3/4"
                  },
                  [
                    _vm.isProductType == "add" && _vm.isProductsRefresh
                      ? _c("AutoCompleteDropdown", {
                          attrs: { options: _vm.products },
                          on: { "selected-item": _vm.setSelectedProduct },
                          model: {
                            value: _vm.selectedProduct,
                            callback: function($$v) {
                              _vm.selectedProduct = $$v
                            },
                            expression: "selectedProduct"
                          }
                        })
                      : _vm._e(),
                    _c("Loader", {
                      attrs: {
                        loading: _vm.isProductLoader,
                        size: _vm.dataLoaderSize
                      }
                    }),
                    _vm.isProductType != "add"
                      ? _c("input", {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model",
                              value: _vm.selectedProduct,
                              expression: "selectedProduct"
                            }
                          ],
                          attrs: {
                            type: "text",
                            title: _vm.selectedProduct,
                            disabled: ""
                          },
                          domProps: { value: _vm.selectedProduct },
                          on: {
                            input: function($event) {
                              if ($event.target.composing) {
                                return
                              }
                              _vm.selectedProduct = $event.target.value
                            }
                          }
                        })
                      : _vm._e()
                  ],
                  1
                )
              ]),
              _c("div", { staticClass: "flex flex-row pb-4 items-center" }, [
                _c(
                  "label",
                  {
                    staticClass: "label w-1/4 font-bold text-right pr-4",
                    class: { hidden: _vm.isTable }
                  },
                  [
                    _vm._v("Variation Name:"),
                    _c("span", { staticClass: "text-red-600" }, [_vm._v("*")])
                  ]
                ),
                _c(
                  "div",
                  {
                    staticClass: "select-box pl-2 relative",
                    class: _vm.isTable ? "w-full" : "w-3/4"
                  },
                  [
                    _vm.isProductType == "add" && _vm.isVariationsRefresh
                      ? _c("AutoCompleteDropdown", {
                          attrs: { options: _vm.variations },
                          on: { "selected-item": _vm.setSelectedVariation },
                          model: {
                            value: _vm.selectedVariation,
                            callback: function($$v) {
                              _vm.selectedVariation = $$v
                            },
                            expression: "selectedVariation"
                          }
                        })
                      : _vm._e(),
                    _c("Loader", {
                      attrs: {
                        loading: _vm.isVariationLoader,
                        size: _vm.dataLoaderSize
                      }
                    }),
                    _vm.isProductType != "add"
                      ? _c("input", {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model",
                              value: _vm.selectedVariation,
                              expression: "selectedVariation"
                            }
                          ],
                          attrs: {
                            type: "text",
                            title: _vm.selectedVariation,
                            disabled: ""
                          },
                          domProps: { value: _vm.selectedVariation },
                          on: {
                            input: function($event) {
                              if ($event.target.composing) {
                                return
                              }
                              _vm.selectedVariation = $event.target.value
                            }
                          }
                        })
                      : _vm._e()
                  ],
                  1
                )
              ]),
              _c(
                "div",
                {
                  staticClass:
                    "product-date-range flex flex-row pb-4 items-center ml-2"
                },
                [
                  _c(
                    "div",
                    {
                      staticClass:
                        "product-startdate flex flex-row items-center"
                    },
                    [
                      _c(
                        "label",
                        {
                          staticClass: "label font-bold text-right pr-6",
                          class: { hidden: _vm.isTable }
                        },
                        [_vm._v("Variation Start Date:")]
                      ),
                      _c(
                        "div",
                        { staticClass: "date-container" },
                        [
                          _c("DatePicker", {
                            attrs: {
                              format: "YYYY-MM-DD",
                              valueType: "format",
                              disabled: ""
                            },
                            model: {
                              value: _vm.variationStartdate,
                              callback: function($$v) {
                                _vm.variationStartdate = $$v
                              },
                              expression: "variationStartdate"
                            }
                          })
                        ],
                        1
                      )
                    ]
                  ),
                  _c(
                    "div",
                    {
                      staticClass: "product-enddate flex flex-row items-center"
                    },
                    [
                      _c(
                        "label",
                        {
                          staticClass: "label font-bold text-right pr-2",
                          class: { hidden: _vm.isTable }
                        },
                        [_vm._v("Variation End Date:")]
                      ),
                      _c(
                        "div",
                        { staticClass: "date-container pl-4" },
                        [
                          _c("DatePicker", {
                            attrs: {
                              format: "YYYY-MM-DD",
                              valueType: "format",
                              disabled: ""
                            },
                            model: {
                              value: _vm.variationEnddate,
                              callback: function($$v) {
                                _vm.variationEnddate = $$v
                              },
                              expression: "variationEnddate"
                            }
                          })
                        ],
                        1
                      )
                    ]
                  )
                ]
              ),
              _c(
                "div",
                { staticClass: "flex flex-row pb-4 items-center ml-12" },
                [
                  _c(
                    "div",
                    {
                      staticClass:
                        "formulation-startdate flex flex-row items-center"
                    },
                    [
                      _c(
                        "label",
                        {
                          staticClass: "label font-bold text-right pr-4",
                          class: { hidden: _vm.isTable }
                        },
                        [
                          _vm._v("CUC Start Date:"),
                          _c("span", { staticClass: "text-red-600" }, [
                            _vm._v("*")
                          ])
                        ]
                      ),
                      _c(
                        "div",
                        { staticClass: "date-container" },
                        [
                          _c("DatePicker", {
                            attrs: {
                              format: "YYYY-MM-DD",
                              valueType: "format",
                              disabled:
                                _vm.selectedCucStatus === "Delisted" ||
                                _vm.selectedCucStatus === "Inactive"
                            },
                            model: {
                              value: _vm.formulationStartDate,
                              callback: function($$v) {
                                _vm.formulationStartDate = $$v
                              },
                              expression: "formulationStartDate"
                            }
                          })
                        ],
                        1
                      )
                    ]
                  ),
                  _c(
                    "div",
                    {
                      staticClass:
                        "formulation-enddate flex flex-row ml-16 items-center"
                    },
                    [
                      _c(
                        "label",
                        {
                          staticClass: "label font-bold text-right pr-4",
                          class: { hidden: _vm.isTable }
                        },
                        [_vm._v("CUC End Date:")]
                      ),
                      _c(
                        "div",
                        { staticClass: "date-container" },
                        [
                          _c("DatePicker", {
                            attrs: {
                              format: "YYYY-MM-DD",
                              disabled: "",
                              valueType: "format"
                            },
                            model: {
                              value: _vm.formulationEnddate,
                              callback: function($$v) {
                                _vm.formulationEnddate = $$v
                              },
                              expression: "formulationEnddate"
                            }
                          })
                        ],
                        1
                      )
                    ]
                  )
                ]
              ),
              _vm.selectedCucStatus === "Delisted"
                ? _c(
                    "div",
                    { staticClass: "addproducts__item flex flex-row pb-4" },
                    [
                      _c(
                        "label",
                        {
                          staticClass: "label w-1/4 font-bold text-right pr-4",
                          class: { hidden: _vm.isTable }
                        },
                        [_vm._v("Historic:")]
                      ),
                      _c(
                        "div",
                        {
                          staticClass: "filters__item ml-2 tooltip",
                          on: {
                            mousedown: function($event) {
                              return _vm.onCheckboxChange()
                            }
                          }
                        },
                        [
                          _c("Checkbox", {
                            attrs: { checked: _vm.isHistoric }
                          }),
                          _vm.isHistoric
                            ? _c("Tooltip", {
                                attrs: {
                                  position: "right",
                                  hoverText: "Formulation will flow to WIOP"
                                }
                              })
                            : _c("Tooltip", {
                                attrs: {
                                  position: "right",
                                  hoverText: "Formulation will not flow to WIOP"
                                }
                              })
                        ],
                        1
                      )
                    ]
                  )
                : _vm._e(),
              _vm.selectedCucStatus === "Delisted" && !_vm.isHistoric
                ? _c(
                    "div",
                    { staticClass: "flex flex-row pb-4 items-center" },
                    [
                      _c(
                        "label",
                        {
                          staticClass: "label w-1/4 font-bold text-right pr-4",
                          class: { hidden: _vm.isTable }
                        },
                        [
                          _vm._v("Comments: "),
                          _c("span", { staticClass: "text-red-600" }, [
                            _vm._v("*")
                          ])
                        ]
                      ),
                      _c(
                        "div",
                        {
                          staticClass: "select-box pl-2 relative",
                          class: _vm.isTable ? "w-full" : "w-3/4"
                        },
                        [
                          _c("textarea", {
                            directives: [
                              {
                                name: "model",
                                rawName: "v-model",
                                value: _vm.comments,
                                expression: "comments"
                              }
                            ],
                            domProps: { value: _vm.comments },
                            on: {
                              input: function($event) {
                                if ($event.target.composing) {
                                  return
                                }
                                _vm.comments = $event.target.value
                              }
                            }
                          })
                        ]
                      )
                    ]
                  )
                : _vm._e()
            ]
          ),
      _c(
        "div",
        { staticClass: "popup-action__button" },
        [
          _vm.isModalLoaded
            ? _c(
                "Button",
                {
                  attrs: { type: "primary", disabled: _vm.isSave },
                  on: {
                    click: function($event) {
                      return _vm.save()
                    }
                  }
                },
                [
                  _c(
                    "svg",
                    {
                      attrs: {
                        xmlns: "http://www.w3.org/2000/svg",
                        viewBox: "0 0 20 20"
                      }
                    },
                    [
                      _c("path", {
                        attrs: {
                          d:
                            "M0 2C0 .9.9 0 2 0h14l4 4v14a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2V2zm5 0v6h10V2H5zm6 1h3v4h-3V3z"
                        }
                      })
                    ]
                  ),
                  _vm._v(" Save")
                ]
              )
            : _vm._e()
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }